import React, { Fragment } from 'react';
import hello from './hello.JPG';

function About() {

	// arrow on click
	const clickContinue = () => {
		// let nextSection = document.getElementById("researchSection");
		// nextSection.scrollIntoView();
		// window.location.href = "#researchSection";
		window.scrollBy(0, 500);
	}
	// I also investigate how to foster the skills (e.g., critical thinking and reasoning) necessary for identifying misinformation and data-based reasoning with the support of visualizations.

	return (
		<Fragment>
		<div class="aboutPage" id="aboutSection">
			<div class="homepage">
				<div>
					<img class="profile" src={hello} alt="profile"/>
				</div>
				
				<div class="about">
					{/*<h2 >Hello!</h2>*/}
					<p>Hello! I am a fourth-year Computer Science PhD candidate at Northwestern University, advised by <a target="_blank" rel="noopener noreferrer" href="https://www.mjskay.com/">Matthew Kay</a>. My research is at the intersection of visualization misinformation and visualization literacy. I am particularly interested in how we can better understand, measure, and improve literacy in visualizations, such as fostering skills to critically evaluate and reason about visual (mis)information. I am supported by the NSF Graduate Research Fellowship.</p>
					<p>I received my B.Sc. in Computer Science from the University of Michigan and have broad interests in Information Visualization, Human-Computer Interaction.</p>
					<div class="news">
						<h3>News</h3>
						<div class="news_scroll">
						<div class="info">
							<p><span role="img" aria-label="paper">&#127908;</span> I will be attending the doctoral colloquium at VIS 2024! | Oct. 2024 </p>
						</div>
						<div class="info">
							<p><span role="img" aria-label="paper">&#128196;</span> Our paper <a href="https://ieeexplore.ieee.org/abstract/document/10670418"><i>Promises and Pitfalls: Using Large Language Models to Generate Visualization Items</i></a> got accepted to VIS 2024! | July, 2024</p>
						</div>
						<div class="info">
							<p><span role="img" aria-label="paper">&#127908;</span> I will be presenting <a href="https://mucollective.northwestern.edu/files/2024-V-FRAMER.pdf">V-FRAMER</a> and co-organizing a <a href="https://visualization-literacy.github.io/CHI2024/">visualization literacy workshop</a> at CHI 2024! | May, 2024</p>
						</div>
						<div class="info">
							<p><span role="img" aria-label="paper">&#128196;</span> Our paper <a href="https://mucollective.northwestern.edu/files/2024-V-FRAMER.pdf"><i>V-FRAMER: Visualization Framework for Mitigating Reasoning Errors in Public Policy</i></a> got accepted to CHI 2024! | March, 2024</p>
						</div>
						<div class="info">
							<p><span role="img" aria-label="paper">&#128196;</span> Our paper <a href="https://lilyge.com/#researchSection"><i>Adaptive Assessment of Visualization Literacy</i></a> got accepted to VIS 2023! | July, 2023</p>
						</div>
						<div class="info">
							<p><span role="img" aria-label="intern">&#128105;&#8205;&#128187;</span> Research fellow in <a target="_blank" rel="noopener noreferrer" href="https://www.mccs.tohoku.ac.jp/g-rips/g-rips2023_en.html">G-RIPS-Sendai 2023</a> at Tohoku University, Japan! | June to August, 2023</p>
						</div>
						<div class="info">
							<p><span role="img" aria-label="travel">&#128747;</span> Attended <a target="_blank" rel="noopener noreferrer" href="https://chi2023.acm.org/">CHI 2023</a> at Hamburg, Germany! | April, 2023</p>
						</div>
						<div class="info">
							<p><span role="img" aria-label="travel">&#128747;</span> Attended the <a target="_blank" rel="noopener noreferrer" href="https://cra.org/cra-wp/grad-cohort-for-women/">2023 CRA-WP Grad Cohort Workshop for Women</a>! | April, 2023</p>
						</div>
						<div class="info">
							<p><span role="img" aria-label="paper_award">&#127881;</span> Our paper <a target="_blank" rel="noopener noreferrer" href="https://dl.acm.org/doi/abs/10.1145/3544548.3581406"><i>CALVI</i></a> got an Honorable Mention at CHI 2023! | April, 2023</p>
						</div>
						<div class="info">
							<p><span role="img" aria-label="award">&#127942;</span> Awarded the <a target="_blank" rel="noopener noreferrer" href="https://www.nsfgrfp.org/">National Science Foundation (NSF) Graduate Research Fellowship</a>! | March, 2023</p>
						</div>
						<div class="info">
							<p><span role="img" aria-label="paper">&#128196;</span> Our paper <a target="_blank" rel="noopener noreferrer" href="https://dl.acm.org/doi/abs/10.1145/3544548.3581406"><i>CALVI</i></a> got accepted to CHI 2023! | March, 2023</p>
						</div>
						<div class="info">
							<p><span role="img" aria-label="travel">&#128747;</span> Attended <a target="_blank" rel="noopener noreferrer" href="https://ieeevis.org/year/2022/welcome">IEEE VIS 2022</a> at Oklahoma City! | October, 2022</p>
						</div>
						<div class="info">
							<p><span role="img" aria-label="travel">&#128747;</span> Attended the <a target="_blank" rel="noopener noreferrer" href="https://cra.org/cra-wp/grad-cohort-for-women/">2022 CRA-WP Grad Cohort Workshop for Women</a>! | April, 2022</p>
						</div>
						</div>
					</div>
				</div>
				
			</div>
			<div class="continue" onClick={clickContinue}></div>
		</div>
		</Fragment>
	)
}

export { About }