import React, { Fragment } from 'react';


function Header() {

	// mailto href
	const clickMail = () => {
		window.location.href = "mailto:wanqian.ge@northwestern.edu";
	}

	// Resume href
	const clickPDF = () => {
		window.open("media/Resume.pdf", "_blank");
	}

	return (
		<Fragment>
		<header>
			<div class="aboutInfo">
				<div>
					<h1>Lily W. Ge</h1>
					<p><span class="fa fa-envelope-o" onClick={clickMail}></span> <a href="mailto:wanqian.ge@northwestern.edu"></a></p>
					<p><span class="fa fa-file-pdf-o" onClick={clickPDF}></span> <a target="_blank" rel="noopener noreferrer" href="media/CV.pdf">CV</a></p>
				</div>
				<div class="headerStyling"></div>
			</div>
			<div class="headerSections">
				<a href="#aboutSection" id="aboutHeader">About</a>
				<a href="#researchSection" id="researchHeader">Research</a>
				<a href="#professionalSection" id="professionalHeader">Teaching Experience</a>
				{/*<a href="#personalProjects">Projects</a>*/}
				<div class="styling"></div>
			</div>
		</header>
		</Fragment>
	)
}

export { Header }