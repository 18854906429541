import React, { Fragment } from 'react';
import { About, Header, Footer } from './';
import { Research, Professional } from '../Experience';
// import { Projects } from '../Projects';

function Homepage() {
  return (
    <Fragment>
    	<Header />
	    <About />
	    <Research />
	    <Professional />
	    {/*<Projects />*/}
	    <Footer />
    </Fragment>
  );
}

export { Homepage }
