import React, { Fragment } from 'react';
import { Footer } from './';

function Redirect() {
  return (
    <Fragment>
    	<div class="redirectContent">
    		<h2>[ Seems like the page is not here ]</h2>
    		<a href="/">Back to homepage?</a>
    	</div>
    	<Footer />
    </Fragment>
  );
}

export { Redirect }
