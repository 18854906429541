import React, { Fragment } from 'react';

function Professional() {
	return (
		<Fragment>
			<div class="professional" id="professionalSection">
				<h2>Teaching Experience</h2>
				<div class="experience">
					<div class="block">
						<p><b>Instructional Aide</b> | College of Engineering, University of Michigan</p>
						<p>EECS 376: Foundations of Computer Science</p>
						<ul>
							<li>Core Computer Science course with a focus on the theory of computation</li>
							<li>Lead weekly discussion and office hours, and participated in homework/exam writing and grading</li>
						</ul>
					</div>
					<div class="block">
						<p><b>Instructional Aide</b> | School of Information, University of Michigan</p>
						<p>SI 339: Web Design, Development, and Accessibility</p>
						<p>SI 539: Design of Complex Websites</p>
						<ul>
							<li>Build auto graders for both undergraduate and graduate level web design/development courses</li>
							<li>Help to automate the testing process for websites and provide feedback to students</li>
						</ul>
					</div>
				</div>
			</div>
		</Fragment>
	)
}

export { Professional }