import React, { Fragment } from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import REACTGA from 'react-ga';
import './App.css';
import { Homepage, Redirect } from './Home';
// import { Research, Professional } from './Experience';
// import { Projects } from './Projects';

function initializeREACTGA() {
    REACTGA.initialize('UA-167277468-1');
    REACTGA.pageview('/homepage');
}

function App() {
  return (
    <Fragment>
    {initializeREACTGA()}
    	<Router>
	      <Switch>
	      	<Route path="/" exact component={Homepage}></Route>
	      	<Route path="/*" exact component={Redirect}></Route>
		  </Switch>
    	</Router>
    </Fragment>
  );
}

export default App;
